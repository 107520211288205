import React, { useContext } from "react";

import { Container, Row, Col, Card, Modal, Button } from "reactstrap";
import SpeakerModal from "components/SpeakerModal.js";
import Slider from "react-slick";
import ShowMoreText from "react-show-more-text";
import { DataContext } from "DataContainer";

function SpeakerGrid() {
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;
  const [open, toggleOpen] = React.useState({
    open: false,
    speaker: {},
  });
  const settings = {
    dots: true,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 1500,
    slidesToShow: 4,
    slidesToScroll: 1,
    rows: 1,
    className: "p-10",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          row: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          row: 1,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const updateModal = (e, speaker) => {
    e.preventDefault();
    toggleOpen({ open: !open.open, speaker: speaker });
  };

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });
  const { speakers } = useContext(DataContext);

  function SpeakerCard({ speaker }) {
    return (
      <a href="/" onClick={(e) => updateModal(e, speaker)}>
        <Card
          className="card-background-image mx-auto p-3"
          style={{
            borderRadius: 0,
            boxShadow: "none",
            background: "transparent",
          }}
        >
          <Container fluid>
            <Row>
              <Col xs={12} className="px-0">
                <img
                  src={speaker.image}
                  alt="speaker"
                  width="100%"
                  className="rounded-circle p-2"
                  style={{ border: "2px #5ab030 dashed" }}
                />
              </Col>

              <Col xs={12} className="px-0">
                <div className="text-center text-white">
                  <h4 className="text-700 mt-2 mb-0 text-g d-block px-0">
                    {speaker.highlightText}
                  </h4>
                  <p className="text-700 mt-2 mb-0 d-block px-0 ">{speaker.name}</p>
                  <p className="text-300 m-0">{speaker.title}</p>
                  <p
                    className="text-400 m-0 d-block px-0"
                    // style={{ fontSize: "16px" }}
                  >
                    {speaker.company}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </Card>
      </a>
    );
  }

  return (
    <>
      <div
        style={{
          background: "transparent",
        }}
        className="section py-5"
        data-parallax={true}
      >
        <div className="filter" />
        <Container>
          <Row>
            <Col lg={12} className="">
              <h1
                className="text-center text-g"
                style={{
                  fontSize: "3rem",
                }}
              >
                2024 SPEAKERS & PANELISTS
              </h1>
            </Col>
          </Row>

          <Row className="py-4">
            {size ? (
              speakers.map((speaker, index) => (
                <Col className="mx-auto px-0" lg={3} xs={12} key={index}>
                  <SpeakerCard speaker={speaker} />
                </Col>
              ))
            ) : (
              <Col xs={11} className={"mx-auto"}>
                <Slider {...settings}>
                  {speakers.map((speaker, index) => (
                    <SpeakerCard speaker={speaker} />
                  ))}
                </Slider>
              </Col>
            )}
          </Row>

          <Row className="justify-content-center">
            <Col lg={12} className="text-center px-1">
              {/* <Button
                href="/past-speakers"
                className="rounded-0 text-400 text-white"
                color="primary"
                size="lg"
                rel="noopener noreferrer"
                target="_blank"
              >
                <span
                  style={{
                    color: "#fff",
                    textTransform: "none",
                    fontWeight: 700,
                    fontSize: "20px",
                  }}
                >
                  PAST EDITION SPEAKERS{" "}
                </span>
              </Button>{" "} */}
              <Button
                href="/register"
                className="rounded-0 text-400 text-white"
                color="primary"
                size="lg"
                rel="noopener noreferrer"
                target="_blank"
              >
                <span
                  style={{
                    color: "#fff",
                    textTransform: "none",
                    fontWeight: 700,
                    fontSize: "20px",
                  }}
                >
                  REGISTER NOW
                </span>
              </Button>
            </Col>
          </Row>
        </Container>

        <Modal isOpen={open.open} toggle={(e) => updateModal(e)} style={{}}>
          <div className="section profile-content">
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={(e) => updateModal(e)}
              style={{
                position: "absolute",
                top: 10,
                right: 10,
                color: "#888888",
              }}
            >
              <span aria-hidden={true}>×</span>
            </button>
            <SpeakerModal speaker={open.speaker} />
          </div>
        </Modal>
      </div>
    </>
  );
}

export default SpeakerGrid;

export const speakers = [
  {
    name: "Eng. Thabet Al Sawyeed",
    title: "Governor",
    sTitle: "KEYNOTE SPEAKER",
    company: "Saudi Contractors Authority, Saudi Arabia",
    text: [""],
    image: "Thabet Photo_High Res.png",
  },
  {
    name: "Eng. Abdullah S Alzoghaibi",
    title: "Urban Design & Geographic Information Director",
    sTitle: "KEYNOTE SPEAKER",
    company: "MODON, Saudi Arabia",

    text: [""],
    image: "Image-PhotoRoom.png",
  },
  {
    name: "Abdulmajeed Mangarah",
    title: "Smart City Program Director",
    company: "Madinah Region Development Authority, Saudi Arabia",
    text: [""],
    image: "Abdulmajeed Albuluwi.jpeg",
  },

  {
    name: "Andrew Rippon",
    title: "Smart City Director",
    company: "Royal Commission for AlUla, Saudi Arabia",
    image: "andrew.jpg",
    text: [``],
  },
  {
    name: "Kevin Harris",
    title: "Digital Twin Delivery Program Manager",
    company: "Royal Commission for Jubail, Saudi Arabia",
    image: "20221006-161258.jpg",
    text: [``],
  },

  {
    name: "Igor Starkov",
    title: "VP Digital Twin",
    company: "Siemens, USA",
    image: "Igor Starkov.jpg",
    text: [``],
  },
  {
    name: "Julie Alexander",
    title: "Smart Cities Director",
    company: "Diriyah Gate Development Authority, Saudi Arabia",
    image: "julie.jpg",
    text: [``],
  },
  {
    name: "Simon Timmis",
    title: "Smart Services Technical Director",
    company: "The Red Sea Development Co, Saudi Arabia",
    image: "Simon Timmis_DT.jpeg",
    text: [``],
  },
  // {
  //   name: "Gigi Karmous-Edwards",
  //   title: "Founder & Co-Chair",
  //   company: "SWAN Digital Twin Workgroup, USA",
  //   text: [""],
  //   image: "GigiKarmous-Edwards.png",
  // },
  {
    name: "Cristina Savian",
    title: "CEO and Founder",
    company: "BE-WISE, Australia",
    text: [""],
    image: "Cristina Savian_DT.jpeg",
  },
  {
    name: "Gaby Abdalla",
    title: "Digital Twin Leader",
    company: "Arup, Netherlands",
    image: "9735GabyAbdalla.jpg",
    text: [``],
  },
  {
    name: "Christopher Seymour",
    title: "Chairman – RICS MENA | Head of Strategy & Investment MEASA",
    company: "Mott MacDonald, UAE",
    image: "Chris Seymour 04.jpg",
    text: [``],
  },
  {
    name: "Brian Zgliczynski",
    title: "Project Scientist",
    company: "Center for Environmental Imaging, USA",
    image: "Brian Zgliczynski.jpeg",
    text: [``],
  },

  {
    name: "Arsen Safaryan",
    title: "Director- Information & Data Management",
    company: "SEVEN, Saudi Arabia",
    image: "Arsen Safaryan.jpg",
    text: [``],
  },

  {
    name: "Amr Saad",
    title: "Associate Director-BIM",
    company: "ROSHN, Saudi Arabia",
    text: [""],
    image: "Amr Saad_DT.jpg",
  },
  // {
  //   name: "Ahmed AbouAlfa",
  //   title: "Head of Digital Delivery and BI",
  //   company: "Diriyah Gate Development Authority, Saudi Arabia ",
  //   image: "Ahmed Abou Alfa.jpg",
  //   text: [``],
  // },
  // {
  //   name: "Gareth Ponmoon",
  //   title: "PMO BIM Lead",
  //   company: "JLL, KSA",
  //   text: [""],
  //   image: "Gareth Ponmoon.jpg",
  // },

  {
    name: "Ibrahim Atta-Apau",
    title: "Director Global Services",
    company: "Atkins, UAE",
    text: [""],
    image: "Ibrahim Atta-Apau.jpeg",
  },

  {
    name: "Paul Doherty",
    title: "President & CEO",
    company: "The Digit Group Inc., USA",
    image: "paul.jpg",
    text: [
      `Paul is President and CEO of The Digit Group, inc. (TDG), a Singapore-registered Smart City real estate development and investment company.  As seen on Bloomberg TV, acknowledged by CNBC as one of America's Business Titans and reported by Forbes as “Changing the World”, Paul is an award-winning architect. a Senior Fellow of the Design Futures Council and a Fellow of the International Facility Management Association (IFMA).  Concurrently, Paul is the co-founder and producer of the critically acclaimed AEC Hackathon (www.aechackathon.com).  Paul’s current work includes the design, planning, construction, and operations of Smart Cities around the world.`,
    ],
  },
  {
    name: "Mohamad Kamal Sherif",
    title: "Digital Delivery Manager",
    company: "Qiddiya Investment Company, Saudi Arabia",
    text: [""],
    image: "Mohammed Kamal Sherif.png",
  },

  {
    name: "Mostafa Elashmawy",
    title: "Senior BIM and GIS Manager",
    company: "WSP, Saudi Arabia",
    text: [""],
    image: "Mostafa Alashmawy.jpg",
  },
  {
    name: "Chris Smeaton",
    title: "Associate Principal - Digital Leader",
    company: "InSite, UAE",
    image: "Chris Smeaton_DT.jpeg",
    text: [``],
  },

  {
    name: "Dr Anas Bataw",
    // sTitle: 'Event Chairman',
    title: "Director",
    company: "Heriot-Watt University, Centre of Excellence in Smart Construction (CESC), UAE",
    image: "anas.jpg",
    text: [``],
  },

  {
    name: "Wissam Hijazi",
    title: "Director- VDC Services- ME",
    company: "EllisDon, UAE",
    image: "wissam.jpg",
    text: [``],
  },
  {
    name: "Ezzat Sabry",
    title: "Managing Director",
    company: "Geomatex, Egypt",
    text: [""],
    image: "Ezzat Sabry.jpg",
  },

  {
    name: "Andy Boutle",
    title: "Head of Digital Construction",
    company: "ALEC, UAE",
    text: [""],
    image: "Andy - ALEC photo (2).jpeg",
  },

  {
    name: "Gary Furphy",
    title: "Digital Engineering Regional Manager",
    company: "Jacobs, UAE",
    image: "gary.jpg",
    text: [``],
  },
  // {
  //   name: "Lindsey Malcolm",
  //   title: "Associate Director-Sustainability",
  //   company: "ASGC, UAE",
  //   image: "Lindsey Malcolm.png",
  //   text: [``],
  // },
  {
    name: "Rohan Chandavarkar",
    title: "Associate Digital Delivery",
    company: "AESG, UAE",
    image: "Rohan Chandavarkar.png",
    text: [``],
  },
  {
    name: "Asmaa A. Atta",
    title: "BIM & Digital Transformation Manager",
    company: "Saudi Diyar Consulting, Egypt",
    image: "Asmaa Atta.png",
    text: [``],
  },
  {
    name: "Mahel Abaab Fournial",
    title: "Cities and Public Services Director",
    company: "Dassault Systemes, France",
    image: "Mahel Abaab Fournial.png",
    text: [``],
  },
  {
    name: "Adel Fernández",
    title: "Public Sector & Smart Cities Lead",
    company: "Esri, UAE",
    image: "Adel Fernandez.jpg",
    text: [``],
  },

  {
    name: "Mazen Abdelmaguid",
    title: "Business Development Manager",
    company: "SoftwareONE, UAE",
    image: "Mazen Aboumaguid.jpg",
    text: [``],
  },
  {
    name: "Paul Surin",
    title: "Built Environment Panel Member",
    company: "The Institution of Engineering and Technology, United Kingdom",
    image: "PaulSurin_1.jpg",
    text: [``],
  },
  {
    name: "Samit Sheth",
    title: "Digital Transformation Lead-MEA",
    company: "JLL, UAE",
    image: "Samit_Sheth_High Res_Color_V1.jpg",
    text: [``],
  },
  {
    name: "Dr Marwan AbuEdbeid",
    title: "Digital Construction Lead",
    company: "Turner Construction, UAE",
    image: "Marwan Abuabeid.jpg",
    text: [``],
  },
  {
    name: "Steven Yule",
    title: "Director of Technology Enabled Solutions",
    company: "Jacobs, UAE",
    image: "Steve Yule.jpg",
    text: [``],
  },
  {
    name: "Darko Macura",
    title: "CEO",
    company: "Alkholi Group, Saudi Arabia",
    image: "Darko Macura_DTSA.jpeg",
    text: [``],
  },
  {
    name: "Ali Khan",
    title: "Area Business Manager",
    company: "Trimble, KSA",
    image: "Ali Khan_Trimble.jpeg",
    text: [``],
  },
  {
    name: "Tareq Masri",
    title: "Director of Sales and Channel",
    company: "Trimble, UAE",
    image: "Tareq Masri_Trimble.jpeg",
    text: [``],
  },
  {
    name: "Firas Fahoum",
    title: "Advisor & Research ",
    company: "DJI",
    image: "firas.jpeg",
    text: [``],
  },
];
